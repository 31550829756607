/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import * as app from '../../ducks/App';
import * as CGCActions from '../../ducks/CGC/CGC-Actions';
import utils from '../../utils';

import { addPassportBundleItem, removePassportBundleItem } from '../../../app/helpers/passportBundleItemStateMutators';
import {
    setFABVariant,
    setABTestingVariant,
    setABTestingLoaded,
    setABTestingTimeout,
} from '../../ducks/App/ducks/ABTesting/ABTesting-Actions';
import { updateABTestingState } from '../../../app/helpers/ABTesting/abTestingReducerHelper';
import { setClickstreamExperiments } from '../../ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

const appActions = app.actions;
const brandActions = app.ducks.brand.actions;

export const INITIAL_STATE = {
    // isLoading: false, //TODO: https://www.google.com/search?q=react+ui+isLoading+pattern&rlz=1C1GGRV_enUS749US750&oq=react+ui+isLoading+pattern&aqs=chrome..69i57.6150j1j7&sourceid=chrome&ie=UTF-8
    // pageId: null,
    urlId: null,
    brandId: null,
    path: null,
    appContext: {
        locationType: 'Residence',
        occasion: {
            name: '',
            code: '',
            occasionCode: '',
        },
        filterProfile: {
            name: '',
            relationship: '',
            relationshipName: '',
        },
        validatedZipcode: '',
        validatedStateByZipcode: '',
        userSubmittedProductFilterZipcode: '',
        isProductFilterZipcodeValid: null,
        userSubmittedAddress: {},
        autoFillAddress: '',
        bannerCode: '',
        passportBundleProductInCart: {
            passportBundleItemCurrentlyInCart: false,
            passportBundleItemSkus: {},
        },
        wineData: null,
        cgcCardData: [],
        cgcCustomCardData: {},
        cgcCardError: '',
        brandMovieSkus: {},
        chatBotOpen: false,

        // guided nav
        guidedNavModalHasBeenLoadedInSession: false,
        validZipcodeSubmittedOnce: false,
        guidedNavLockedProductClicked: false,
        guidedNavProductUnlock: false,
        wineAgeValidation: false,
        miniCartUpsell: false,
        scrollPosition: null,
        isMiniCartModalOpen: false,
        abcChatLink: '',
        productUnAvailable: false,
        isEmailCaptured: false,
        isHoldoutPersonalizationEnabled: false,
    },
    currentProduct: {
        gciData: null,
        gciKey: null,
        addToCartPosition: null,
        userConfirmationForSubscriptionPopup: false,
        twillioChatBotMessage: false,
        selectedInlineAddons: [],
        selectedBYOItem: {},
        addCaCampaign: {},
    },
    pdpCart: {
        parentProductBrandId: null,
        selectedSKUProduct: null,
        selectedCYOProducts: null,
        selectedCYOComponentCount: 0,
        selectedPYOProducts: null,
        maxCYOComponents: 0,
        recipients: null,
        zipcodeData: null,
        wineData: null,
    },
    suggestData: {},
    country: 'united states',
    sideMiniCart: false,
    hasCartSliderOpened: 'close',
    loginModule: {
        showBanner: true,
    },
    donationsCategory: '',
    emailSubscription: {},
    fab: {},
    abTesting: {},
    subscriptionDetail: {},
    isInPassportHoldout: false,
    passportHoldoutGroupTracking: '',
    experiments: [],
    emailOptInSalesforceResponse: {},
    emailOptInAPIRefetchTimestamp: 0,
    dialog: {
        isOpen: false,
        type: '',
        offerGateway: {
            salesforceResponse: {},
            offerGatewayOptin: false,
            ctaLabel: '',
            email: '',
            offerScrolled: false,
            offerUnlocked: false,
        },
    },
    wrapUpProductRefTitle: 'Default',
};

const uiPersistConfig = {
    key: 'ui',
    storage: sessionStorage,
    whiteList: [
        'appContext',
        'abTesting',
    ],
    blacklist: [
        'fab',
    ],
};

export default persistReducer(uiPersistConfig, utils.createReducer(INITIAL_STATE)({
    // App
    [appActions.setAppShellLoadState().type]: (state, action) => createState(state || INITIAL_STATE, ['pageShellLoaded', action.payload]),
    [brandActions.brandLoaded().type]: (state, action) => createState(state || INITIAL_STATE, ['brandId', action.id]),
    [appActions.setSearchResult().type]: (state, action) => createState(state || INITIAL_STATE, ['suggestData', action.payload]),
    [appActions.clearSearchResult().type]: (state, action) => createState(state || INITIAL_STATE, ['suggestData', INITIAL_STATE.suggestData || action.payload]),
    // These two reducers change the category locked status in state.
    // [appActions.lockCategoryPage().type]: state =>
    //   createState(state || INITIAL_STATE, ['category.locked', true]),
    // [appActions.unlockCategoryPage().type]: state =>
    //   createState(state || INITIAL_STATE, ['category.locked', false]),

    // App Context
    // These two reducers set and clear the zipcode which is used to filter products based on availability.
    [appActions.setValidatedZipcode().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.validatedZipcode', action.payload]),

    [appActions.setValidatedStateOfZipcode().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.validatedStateByZipcode', action.payload || '']),

    [appActions.clearValidatedZipcode().type]: (state) => createState(state || INITIAL_STATE, ['appContext.validatedZipcode', '']),
    [appActions.setUserSubmittedProductFilterZipcode().type]: (state, action) => createState(state || INITIAL_STATE,
        ['appContext.userSubmittedProductFilterZipcode', action.payload],
        ['appContext.validZipcodeSubmittedOnce', true], // only ever sets to true
        ['appContext.guidedNavLockedProductClicked', false],
    ),
    [appActions.setGuidedNavLockedProductClicked().type]: (state) => createState(state || INITIAL_STATE, ['appContext.guidedNavLockedProductClicked', true]),
    [appActions.clearUserSubmittedProductFilterZipcode().type]: (state) => createState(state || INITIAL_STATE, ['appContext.userSubmittedProductFilterZipcode', '']),
    [appActions.setProductFilterZipcodeValidity().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.isProductFilterZipcodeValid', action.payload]),
    [appActions.resetProductFilterZipcodeValidity().type]: (state) => createState(state || INITIAL_STATE, ['appContext.isProductFilterZipcodeValid', null]),
    [appActions.setValidZipcodeSubmmitedOnce().type]: (state) => createState(state || INITIAL_STATE, ['appContext.validZipcodeSubmittedOnce', true]),
    // These two reducers set and clear the location type which is used to filter products based on availability.
    [appActions.setLocationType().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.locationType', action.payload]),
    [appActions.clearLocationType().type]: (state) => createState(state || INITIAL_STATE, ['appContext.locationType', '']),
    // These two reducers set and clear a gift location which can be set by the find a gift fast widget.
    [appActions.setProductFilterOccasion().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.occasion', action.payload]),
    [appActions.clearProductFilterOccasion().type]: (state) => createState(state || INITIAL_STATE, ['appContext.occasion', { name: '', code: '', occasionCode: '' }]),
    // These two reducers set and clear any address that can be selected by the user before checkout (guided nav / address book etc)
    [appActions.setUserSelectedAddress().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.userSubmittedAddress', action.payload]),
    [appActions.clearUserSelectedAddress().type]: (state) => createState(state || INITIAL_STATE, ['appContext.userSubmittedAddress', {}]),
    [appActions.setAddressSource().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.userSubmittedAddress.addressSource', action.payload]),
    [appActions.setAutoFillAddress().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.autoFillAddress', action.payload]),
    // Set bannercode for persistent eybrow in cross browsing
    [appActions.setBannerCode().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.bannerCode', action.payload]),
    [appActions.clearBannerCode().type]: (state) => createState(state || INITIAL_STATE, ['appContext.bannerCode', '']),
    [appActions.passportBundleItemAddedToCart().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.passportBundleProductInCart', addPassportBundleItem(state.appContext.passportBundleProductInCart, action.payload)]),
    [appActions.passportBundleItemRemovedCart().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.passportBundleProductInCart', removePassportBundleItem(state.appContext.passportBundleProductInCart, action.payload)]),

    [appActions.setWineData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.wineData', action.payload]),
    [appActions.resetWineData().type]: (state) => createState(state || INITIAL_STATE, ['appContext.wineData', null]),

    [appActions.resetAgeVerifyFlag().type]: (state) => createState(state || INITIAL_STATE, ['appContext.isAgeVerified', null]),
    [appActions.setAgeVerifyFlagValid().type]: (state) => createState(state || INITIAL_STATE, ['appContext.isAgeVerified', true]),
    [appActions.setBrandMovieSkus().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.brandMovieSkus', { ...state.appContext.brandMovieSkus, ...action.payload }]),
    // This reducer is to set the status of email subscription box
    [appActions.setEmailSubscriptionData().type]: (state, action) => createState(state || INITIAL_STATE, ['emailSubscription', action.payload]),
    [appActions.resetEmailSubscription().type]: (state) => createState(state || INITIAL_STATE, ['emailSubscription', {}]),

    [appActions.setGCIData().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.gciData', action.payload]),
    [appActions.clearGCIData().type]: (state) => createState(state || INITIAL_STATE, ['currentProduct.gciData', {}]),
    [appActions.setGCIKey().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.gciKey', action.payload]),
    [appActions.setProductRetailPrice().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.retailPrice', action.payload]),
    [appActions.setUserConfirmationForSubscriptionPopup().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.userConfirmationForSubscriptionPopup', action.payload]),

    // reducer to set the position of the addtoCart button
    [appActions.setAddtoCartPosition().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.addToCartPosition', action.payload]),
    // reducer to set the country name
    [appActions.setCountry().type]: (state, action) => createState(state || INITIAL_STATE, ['country', action.payload]),

    // reducer to set the Category name
    [appActions.setDonationsCategory().type]: (state, action) => createState(state || INITIAL_STATE, ['donationsCategory', action.payload]),

    // Open and close chatbot
    [appActions.setChatbotOpenState().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.chatBotOpen', action.payload]),

    // Collection page scroll position. Remember position on long collections when coming back from PDP.
    [appActions.setScrollPosition().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.scrollPosition', action.payload]),

    // Keeps track of whether guided nav modal has already been shown to user
    [appActions.setGuidedNavModalHasBeenOpenedInSession().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.guidedNavModalHasBeenLoadedInSession', action.payload]),

    // reducer to set cgc card data
    [CGCActions.resetCGCCardData().type]: (state) => createState(state || INITIAL_STATE, ['appContext.cgcCardData', []]),
    [CGCActions.setCGCCardData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.cgcCardData', action.payload]),
    [CGCActions.setCGCCustomCardData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.cgcCustomCardData', action.payload]),
    [CGCActions.setCGCCardError().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.cgcCardError', action.payload]),

    // Mini cart upsell state
    [appActions.setMiniCartUpsellState().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.miniCartUpsell', action.payload]),
    [appActions.setMiniCartModalState().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.isMiniCartModalOpen', action.payload]),

    // fastly
    [setFABVariant().type]: (state, action) => createState(state || INITIAL_STATE, ['fab', { ...state.fab, ...action.payload }]),

    // AB Testing Optimize
    [setABTestingVariant().type]: (state, action) => createState(state || INITIAL_STATE, ['abTesting', updateABTestingState(state.abTesting, action)]),
    [setABTestingLoaded().type]: (state, action) => createState(state || INITIAL_STATE, ['abTesting', { ...state.abTesting, ...action.payload }]),
    [setABTestingTimeout().type]: (state, action) => createState(state || INITIAL_STATE, ['abTesting', { ...state.abTesting, ...action.payload }]),

    [appActions.removeGCIKey().type]: (state) => createState(state || INITIAL_STATE, ['currentProduct.gciKey', null]),

    [appActions.setTwillioMessageChatBot().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.twillioChatBotMessage', action.payload]),

    // reducer to subcription  detail on pdp page
    [appActions.setSubscriptionDetail().type]: (state, action) => createState(state || INITIAL_STATE, ['subscriptionDetail', action.payload]),

    // reducer to check side mini cart visible
    [appActions.setSideMiniCart().type]: (state, action) => createState(state || INITIAL_STATE, ['sideMiniCart', action.payload]),

    // reducer to set passport holdout group
    [appActions.setAppPassportHoldoutGroup().type]: (state, action) => createState(state || INITIAL_STATE, ['isInPassportHoldout', action.payload]),
    [appActions.setAppPassportHoldoutGroupTracking().type]: (state, action) => createState(state || INITIAL_STATE, ['passportHoldoutGroupTracking', action.payload]),
    // reducer to show/hide mini cart panel
    [appActions.setCartSliderOpened().type]: (state, action) => createState(state || INITIAL_STATE, ['hasCartSliderOpened', action.payload]),

    // reducer to set IOS(ABC) chat link
    [appActions.setAbcChatLink().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.abcChatLink', action.payload]),

    [appActions.setGuidedNavProductUnlock().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.guidedNavProductUnlock', action.payload]),

    [appActions.setWineAgeVerify().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.wineAgeValidation', action.payload]),

    [appActions.setProductUnAvailable().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.productUnAvailable', action.payload]),

    [appActions.setSelectedInlineAddons().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.selectedInlineAddons', action.payload]),
    [appActions.setLoginModuleBannerVisibility().type]: (state, action) => createState(state || INITIAL_STATE, ['loginModule.showBanner', action.payload]),
    [appActions.setSelectedBYOItems().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.selectedBYOItem', action.payload]),
    [appActions.setProductFilterProfile().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.filterProfile', action.payload]),

    // clickstream experiments
    [setClickstreamExperiments().type]: (state, action) => createState(state || INITIAL_STATE, ['experiments', action.payload]),
    [appActions.setEmailCaptureStatus().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.isEmailCaptured', action.payload]),
    [appActions.setHoldoutPersonalizationStatus().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.isHoldoutPersonalizationEnabled', action.payload]),
    [appActions.setEmailOptInSFResponse().type]: (state, action) => createState(state || INITIAL_STATE, ['emailOptInSalesforceResponse', action.payload]),
    [appActions.resetEmailOptInSFResponse().type]: (state) => createState(state || INITIAL_STATE, ['emailOptInSalesforceResponse', {}]),
    [appActions.setEmailOptInRefetchTimestamp().type]: (state, action) => createState(state || INITIAL_STATE, ['emailOptInAPIRefetchTimestamp', action.payload]),
    [appActions.setDialogOpen().type]: (state, action) => createState(state || INITIAL_STATE, ['dialog.isOpen', action.payload]),
    [appActions.setDialogType().type]: (state, action) => createState(state || INITIAL_STATE, ['dialog.type', action.payload]),
    [appActions.setOfferGatewayData().type]: (state, action) => createState(state || INITIAL_STATE, ['dialog.offerGateway', { ...state.dialog.offerGateway, ...action.payload }]),
    [appActions.setOfferGatewayCTALabel().type]: (state, action) => createState(state || INITIAL_STATE, ['dialog.offerGateway.ctaLabel', action.payload]),

    [appActions.setWrapUpProductRefTitle().type]: (state, action) => createState(state || INITIAL_STATE, ['wrapUpProductRefTitle', action.payload]),
    [appActions.setCaCampaign().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.addCaCampaign', action.payload]),

}));
